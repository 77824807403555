import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

import * as styles from './photos.module.scss'
import { useState } from "react"
import PageHeader from "../components/PageHeader/PageHeader"
import { useTranslation } from "react-i18next"

const PhotosPage = ({ data }) => {
  const [fullScreenPhoto, setFullScreenPhoto] = useState<string>(null);
  const { t } = useTranslation();

console.log(data)

  // Set ImageData.
  const imageData = data.desktop.nodes
    .map(f => {
      const sectionMatch = f.absolutePath.match(/\/images\/sections\/([^/]+)\//)
      if (!sectionMatch) {
        console.log(f.absolutePath)
      }

      const section =
        sectionMatch && sectionMatch.length === 2 ? sectionMatch[1] : null
      return { name: f.name, image: f, section }
    })
  //  .filter(f => f.section === currentSection)

  return (
    <div>
        <PageHeader title={t(data.context.pageContext.section)} section={data.context.pageContext.section} />

        <main>

        <div
        style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        }}
        >
        {imageData.map((d, i) => (
            <div
            key={i}
            className={i % 3 === 0 ? styles.fullWidth : styles.halfWidth}
            >
            <GatsbyImage alt="image" image={getImage(d.image)} />
            </div>
        ))}
        </div>
        </main>
    </div>
  )
}

export default PhotosPage

export const query = graphql`
  query PhotosPage($language: String!, $photosRegExp: String!, $path: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    context: sitePage(path: { eq: $path }) {
      pageContext
    }
    desktop: allFile(filter: { relativePath: { regex: $photosRegExp } }) {
      nodes {
        name
        absolutePath
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 720
            aspectRatio: 1.4
            quality: 80
          )
        }
      }
    }
  }
`
